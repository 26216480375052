import { CircleAlert } from "lucide-react";
import React from "react";

const UserNameInput = ({
  firstNameVal,
  lastNameVal,
  handleFormData,
  error,
}: any) => {
  return (
    <>
      <div className="flex items-center gap-4">
        <div className="relative w-full">
          <input
            type="text"
            name="firstName"
            value={firstNameVal}
            onChange={(e) => {
              handleFormData("firstName", e.target.value);
            }}
            placeholder="First Name *"
            className={`inputClass ${
              error === ""
                ? "focus:ring-indigo-600"
                : "focus:ring-red-600 text-red-900"
            }`}
          />

          {error !== "" && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <CircleAlert
                aria-hidden="true"
                className="h-5 w-5 text-red-500"
              />
            </div>
          )}
        </div>

        <input
          type="text"
          name="lastName"
          value={lastNameVal}
          onChange={(e) => {
            handleFormData("lastName", e.target.value);
          }}
          placeholder="Last Name"
          className="inputClass"
        />
      </div>

      <span className="text-red-600 text-xs font-medium mb-2">{error}</span>
    </>
  );
};

export default UserNameInput;
