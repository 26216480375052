import React, { useEffect, useState } from "react";
import "./App.css";
import { Toaster } from "sonner";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";

import RegisterRouting from "./components/routing/RegisterRouting";
import TokenLogin from "./components/shared/TokenLogin";
import { GetLocationDetails } from "./api/_request";
import { useAppState } from "./context/AppStateContext";
import { ActionTypes } from "./reducer/actionTypes";
import { Helmet } from "react-helmet";

import useFavicon from "./hooks/useFavicon";
import { isHostMatch } from "./lib/utils";
import GTMComponent from "./components/tracking/GTMComponent";

const App: React.FC = () => {
  //Dynamically Change favicon
  const faviconUrl =
    window.location.host.includes(".ask-sam.ai") ||
    window.location.host.includes(".asksam.com.au")
      ? "/faviconAskSam.ico" // AskSam favicon
      : "/favicon.ico"; // UWC favicon
  useFavicon(faviconUrl);

  const { state, dispatch } = useAppState();

  //const [appLogo, setAppLogo] = useState(process.env.REACT_APP_UWC_LOGO);

  useEffect(() => {
    const hostMatch =
      window.location.host.includes(".ask-sam.ai") ||
      window.location.host.includes(".asksam.com.au");

    updateLogo();
    updatePrimaryColor();

   // document.title = hostMatch ? "Auth AskSam" : "Auth UWC";
  }, []);

  // const getIPDetails = async () => {
  //   try {
  //     const ipDetails = await GetLocationDetails();
  //     if (ipDetails?.status === 200) {
  //       const ipCountry = ipDetails?.data?.data?.country?.toUpperCase() || "";

  //       dispatch({
  //         type: ActionTypes.SET_COUNTRY,
  //         payload: ipCountry,
  //       });

  //       updateLogo(ipCountry);
  //      // updatePrimaryColor(ipCountry);
  //     }
  //   } catch (error) {
  //     updateLogo(); // fallback in case of error
  //   }
  // };

  const updateLogo = () => {
    const hostMatch =
      window.location.host.includes(".ask-sam.ai") ||
      window.location.host.includes(".asksam.com.au");

    const logo = hostMatch
      ? process.env.REACT_APP_ASKSAM_LOGO
      : process.env.REACT_APP_UWC_LOGO;

    dispatch({
      type: ActionTypes.SET_APP_LOGO,
      payload: logo,
    });
  };

  const updatePrimaryColor = () => {
    const hostMatch =
      window.location.host.includes(".ask-sam.ai") ||
      window.location.host.includes(".asksam.com.au");

    if (hostMatch) {
      document.documentElement.style.setProperty("--primary", "#0c1c44");
    } else {
      document.documentElement.style.setProperty("--primary", "#73589B");
    }
  };

  return (
    <main>
      <GTMComponent />
      <Router>
        <div className="flex h-screen w-screen overflow-hidden">
          <TokenLogin />
          <div className="hidden xl:flex xl:flex-col justify-between xl:gap-4 flex-1 bg-[#000601] relative max-w-[800px]">
            <img
              src={state?.appLogoUrl || ""}
              alt="logo"
              width={150}
              className="mt-10 object-contain mb-2 ml-8 brightness-[50] z-10 absolute"
            />
            <img
              src={process.env.REACT_APP_LOGIN_IMAGE_URL}
              alt=""
              className="w-full h-screen object-cover"
            />
            {/* <code className="text-white mb-10  text-xs max-w-xl text-center mx-auto">
              Your privacy and security are our top priority. All interactions
              on this platform are protected with advanced encryption and
              managed with strict confidentiality. We implement robust security
              measures to safeguard your personal information
            </code> */}
          </div>
          <div className="flex-1 flex flex-col items-center justify-center px-6 py-14 overflow-hidden h-screen">
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/register/*" element={<RegisterRouting />} />
            </Routes>
          </div>
        </div>
      </Router>
      <Toaster richColors expand={true} />
    </main>
  );
};

export default App;
